import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faFlag} from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import {Button, Form} from "react-bootstrap";
import {IBVoidFunction} from "../../types";
import {DocumentData} from "firebase/firestore";
import ClientNameWithDetails from "../../common/ClientNameWithDetails";

export interface CrmActivityListItemProps {
    data: DocumentData;
    isSelected?: boolean;
    onToggle?: IBVoidFunction;
    onDetails: IBVoidFunction;
    className?: string;
}

export default function CrmActivityListItem({
                                                data,
                                                isSelected,
                                                onDetails,
                                                onToggle,
                                                className
                                            }: CrmActivityListItemProps) {
    let _data = {...data};
    const hasDetails = _data.details && _data.details.length > 0;
    return (<>
            <tr className={className + (_data.withPriority ? " fw-bold text-warning" : "")}>
                <td rowSpan={hasDetails ? 2 : 1}>
                    <div className={"w-100 text-center text-danger"}>
                        {
                            _data.activityStatus === "приет" ?
                                <span className={"text-success"}><FontAwesomeIcon icon={faFlag}/></span> :
                                (_data.activityStatus === "не отговаря" ?
                                    <span className={"text-secondary"}><FontAwesomeIcon icon={faFlag}/></span> :
                                    (_data.activityStatus === "отказано/отменено" ?
                                            <span className={"text-danger"}><FontAwesomeIcon icon={faFlag}/></span> :
                                            (_data.withPriority ?
                                                <span className={"text-primary"}><FontAwesomeIcon
                                                    icon={faFlag}/></span> : null)
                                    ))
                        }
                    </div>
                </td>
                <td rowSpan={hasDetails ? 2 : 1}> {moment(_data.date.seconds * 1000).format("DD.MM.yyyy HH:mm") || ''} </td>
                <td> {_data.campaignName || ''} </td>
                <td> {_data.region || ''} </td>
                <td><ClientNameWithDetails clientLocationId={_data.clientId} clientLocationName={_data.clientName}/>
                </td>
                <td> {_data.activityType} </td>
                <td> {_data.activityStatus} </td>
                <td>
                    {
                        onToggle &&
                        <Form.Check className={"text-center m-0"} type={"checkbox"} checked={isSelected}
                                    onClick={() => onToggle()} onChange={() => {
                        }}
                        />
                    }
                </td>
                <td className={"text-end"}>
                    <Button size={"sm"}
                            onClick={e => onDetails()}>
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </Button>
                </td>
            </tr>
            {
                hasDetails &&
                <tr>
                    <td colSpan={7} style={{maxWidth: "40vw"}}>
                        <div className={"text-muted w-100 text-truncate"} >{_data.details}</div>
                    </td>
                </tr>
            }
        </>
    );
}
