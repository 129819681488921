import {Button, Card, Col, Container, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {useEffect, useMemo, useState} from "react";
import CRMSummaryReportFilter, {CRMSummaryReportFilterType} from "./CRMSummaryReportFilter";
import {fbDb} from "../../../../App";
import {
    collection,
    doc,
    getDocs,
    limit,
    query,
    QueryCompositeFilterConstraint,
    QueryConstraint,
    updateDoc,
    where,
    writeBatch
} from "firebase/firestore";
import {customerInquiryConverter, CustomerInquiryType} from "../../../../fb-converters/cutomerInquiry-converter";
import {customerInvoiceConverter, CustomerInvoiceType} from "../../../../fb-converters/cutomerInvoice-converter";
import {customerOfferConverter} from "../../../../fb-converters/cutomerOffer-converter";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {dateToString} from "../../../../common";

type reportItemType = {
    randId: string;
    clientId: string;
    clientName: string;
    inquiryId: string;
    inquiryNumber: string;
    inquiryDate: string;
    offerId: string;
    offerNumber: string;
    offerDate: string;
    invoiceId: string;
    invoiceNumber: string;
    invoiceDate: string;
}

export default function CRMSummaryReportPage() {
    const [filter, setFilter] = useState<CRMSummaryReportFilterType | null>(null);

    const filterControl = useMemo(
        () => <CRMSummaryReportFilter onChange={setFilter}/>,
        []
    );

    async function calculate() {
        return;
        const allInquiriesData = await getDocs(
            query(
                collection(fbDb, 'customerInquiries').withConverter(customerInquiryConverter),
                where('inquiryDate', '>=', new Date(2024, 1, 1)),
                // limit(10)
            )
        );
        const allInquiries = allInquiriesData.docs.map(d => d.data());

        const allInvoicesData = await getDocs(
            query(
                collection(fbDb, 'customerInvoices').withConverter(customerInvoiceConverter),
                where('invoiceDate', '>=', new Date(2024, 1, 1)),
            )
        );
        const allInvoices = allInvoicesData.docs.map(d => d.data());

        for (const i of allInquiries) {
            const inqueryAllOffersData = await getDocs(
                query(
                    collection(fbDb, `customerInquiries/${i.id}/offers`).withConverter(customerOfferConverter)
                )
            );
            const inqueryAllOffers = inqueryAllOffersData.docs.map(d => d.data());

            await updateDoc(
                doc(fbDb, `customerInquiries/${i.id}`),
                {
                    offers: inqueryAllOffers.map(o => ({
                        offerId: o.id,
                        offerDate: o.offerDate,
                        offerNumber: o.offerNumber
                    }))
                }
            );

        }
        ;
    }

    const invoiceQuery = useMemo(
        () => {
            const hasFilter = filter !== null && filter.fromDate && filter.toDate;
            if(!hasFilter) return null;
            const invoicesCollection = collection(fbDb, 'customerInvoices').withConverter(customerInvoiceConverter);
            const constraints: QueryConstraint[] = [];
            if(filter.fromDate) {
                constraints.push(where('invoiceDate', '>=', filter.fromDate));
            }
            if(filter.toDate) {
                constraints.push(where('invoiceDate', '<=', filter.toDate));
            }
            if(filter.client) {
                constraints.push(where('client.id', '==', filter.client.id));
            }

            return query(invoicesCollection, ...constraints);
        },
        [filter]
    );

    const inquiryQuery = useMemo(
        () => {
            const hasFilter = filter !== null && filter.fromDate && filter.toDate;
            if(!hasFilter) return null;
            const inquiriesCollection = collection(fbDb, 'customerInquiries').withConverter(customerInquiryConverter);
            const constraints: QueryConstraint[] = [];
            if(filter.fromDate) {
                constraints.push(where('inquiryDate', '>=', filter.fromDate));
            }
            if(filter.toDate) {
                constraints.push(where('inquiryDate', '<=', filter.toDate));
            }
            if(filter.client) {
                constraints.push(where('client.id', '==', filter.client.id));
            }

            return query(inquiriesCollection, ...constraints);
        },
        [filter]
    );

    const [inqueryData, inqueryLoading, inqueryError] = useCollectionData<CustomerInquiryType>(inquiryQuery);
    const [invoiceData, invoiceLoading, invoiceError] = useCollectionData<CustomerInvoiceType>(invoiceQuery);

    const updateInquiryOffers = async (inquiryId: string) =>
    {
        const inqueryAllOffersData = await getDocs(
            query(
                collection(fbDb, `customerInquiries/${inquiryId}/offers`).withConverter(customerOfferConverter)
            )
        );
        const inqueryAllOffers = inqueryAllOffersData.docs.map(d => d.data());

        await updateDoc(
            doc(fbDb, `customerInquiries/${inquiryId}`),
            {
                offers: inqueryAllOffers.map(o => ({
                    offerId: o.id,
                    offerDate: o.offerDate,
                    offerNumber: o.offerNumber
                }))
            }
        );
    }

    useEffect(() => {
        if (invoiceLoading || inqueryError) return;

        const batch = writeBatch(fbDb);
        inqueryData?.map(
            i => {
                if((!i.offers || i.offers.length === 0) && i.id) {
                    updateInquiryOffers(i.id);
                }
            }
        );

    }, [inqueryData]);

    const reportData = useMemo(() => {
        if(inqueryData === undefined || invoiceData === undefined) return null;
        const result: reportItemType[] = [];
        for(const i of inqueryData) {
            const inquiryId = i.id ?? "";
            const inquiryNumber = i.inquiryNumber?.toString().padStart(6, "0") ?? "";
            const inquiryDate = dateToString(i.inquiryDate) ?? "";
            const clientId = i.client?.id ?? "";
            const clientName = i.client?.name ?? "";

            for(const o of i.offers) {
                const offerId = o.offerId ?? "";
                const offerNumber = o.offerNumber?.toString().padStart(6, "0") ?? "";
                const offerDate = dateToString(o.offerDate) ?? "";

                const invoice = invoiceData.find(i => i.offerId === offerId && i.documentType === "потвърждение на поръчка");

                const invoiceId = invoice?.id ?? "";
                const invoiceNumber = invoice?.invoiceNumber?.toString().padStart(6, "0") ?? "";
                const invoiceDate = dateToString(invoice?.invoiceDate) ?? "";

                result.push({
                    randId: Math.random().toString(),
                    clientId,
                    clientName,
                    inquiryId,
                    inquiryNumber,
                    inquiryDate,
                    offerId,
                    offerNumber,
                    offerDate,
                    invoiceId,
                    invoiceNumber,
                    invoiceDate
                });
            }
        }
        return result;
    }, [inqueryData, invoiceData]);

    console.log("reportData", reportData);

    return <Container>
        <Card>
            <Card.Header>
                <Card.Title>CRM - обобщена справка</Card.Title>
            </Card.Header>

            <Card.Body>
                {filterControl}

                {
                    !reportData && <Row>
                        <Col className={"text-center"}>
                            Моля, изчакайте...
                        </Col>
                    </Row>
                }

                {
                    inqueryError && <Row className={"text-center"}>
                        <Col>
                            <div className={"text-danger"}>{inqueryError.message}</div>
                        </Col>
                    </Row>
                }
                {
                    invoiceError && <Row className={"text-center"}>
                        <Col>
                            <div className={"text-danger"}>{invoiceError.message}</div>
                        </Col>
                    </Row>
                }

                {
                    (inqueryLoading || invoiceLoading) && <Row className={"text-center"}>
                        <Col>
                            <Spinner animation={"border"}/> Зареждане ...
                        </Col>
                    </Row>
                }

                {
                    reportData && <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th rowSpan={2}>Клиент</th>
                            <th colSpan={2}>Запитване</th>
                            <th colSpan={2}>Оферта</th>
                            <th colSpan={2}>Потв.поръчка</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            reportData.map(
                                i => <tr key={i.randId}>
                                    <td>{i.clientName}</td>
                                    <td>{i.inquiryNumber}</td>
                                    <td>{i.inquiryDate}</td>
                                    <td>{i.offerNumber}</td>
                                    <td>{i.offerDate}</td>
                                    <td>{i.invoiceNumber}</td>
                                    <td>{i.invoiceDate}</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                }

            </Card.Body>

        </Card>

    </Container>
}
