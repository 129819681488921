import {Button, CloseButton, Col, Container, Form, Modal, Row, Table} from "react-bootstrap";
import ModalBackground from "../../../../common/ModalBackground";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {useReactToPrint} from "react-to-print";
import {useRef} from "react";
import "./ExpeditionPlanPrintPreview.css";
import {ExpeditionCourseType} from "../../expedition-plan/ExpeditionCourse";
import {dateToString} from "../../../../common";
import {ExpeditionType} from "../../../../fb-converters/expedition-converter";

interface Props {
    zIndex?: number;
    expeditionCourse: ExpeditionCourseType;
    expeditions: ExpeditionType[];
    onDismiss: () => void;
}

function PageHeader({expeditions} : {expeditions: ExpeditionType[]}) {
    if (expeditions.length === 0) {
        return null;
    }

    const firstExpedition = expeditions[0];
    const expeditionDate = dateToString(firstExpedition.expeditionDate);
    const driverName = firstExpedition.driver?.name ?? "";
    const carName = firstExpedition.car?.name ?
        `${firstExpedition.car.name} ${firstExpedition.car.regPlate ? "[ " + firstExpedition.car.regPlate + " ]" : ""} `
        : "";

    return (
        <Row className={"mt-2 mb-3"}>
            <Col>Дата:<b className={"ms-2"}>{expeditionDate}</b></Col>
            <Col>Шофьор:<b className={"ms-2"}>{driverName}</b></Col>
            <Col>Кола:<b className={"ms-2"}>{carName}</b></Col>
        </Row>
    );
}

export default function ExpeditionPlanPrintPreview({expeditionCourse, expeditions, zIndex, onDismiss}: Props) {
    const _zIndex = zIndex || 1000;
    const componentRef = useRef(null);




    const printContent = (
        <Container fluid ref={componentRef} className={"p-5"}>
            {/*<div className={"d-none d-print-block m-5"}>&nbsp;</div>*/}
            <Row>
                <h2 className={"text-uppercase w-100 text-center"}>ПЛАН ЗА ЕКСПЕДИЦИЯ</h2>
            </Row>

            <PageHeader expeditions={expeditions} />

            <Row>
                <Table className={"items"}>
                    <thead>
                    <tr>
                        <th className={"text-center"} rowSpan={2}>№</th>
                        <th className={"text-center"} rowSpan={2}>Запитване</th>
                        <th className={"text-center"} rowSpan={2}>Посока</th>
                        <th className={"text-center"} colSpan={3}>Клиент</th>
                        <th className={"text-center"} rowSpan={2}>Допълнителна информация</th>
                        <th className={"text-center"} colSpan={2}>Заминаване (кг)</th>
                        <th className={"text-center"} colSpan={2}>Връщане (кг)</th>
                        <th className={"text-center"} rowSpan={2}>Палети (зам)</th>
                        <th className={"text-center"} rowSpan={2}>Палети (вр)</th>
                    </tr>
                    <tr>
                        <th className={"text-center"}>Име</th>
                        <th className={"text-center"}>Адрес</th>
                        <th className={"text-center"}>Телефон</th>
                        <th className={"text-center"}>Пратка</th>
                        <th className={"text-center"}>Палет</th>
                        <th className={"text-center"}>Скрап</th>
                        <th className={"text-center"}>Доставка</th>
                    </tr>
                    </thead>
                    <tbody>
                    {expeditions.map((exp, index) => (
                        <tr key={index}>
                            <td className={"text-center"}>{index + 1}</td>
                            <td>{exp.inquiry?.inquiryNumber} / {dateToString(exp.inquiry?.inquiryDate)}</td>
                            <td>{exp.client?.region}</td>
                            <td>{exp.invoiceClientName}</td>
                            <td>{exp.client?.address}</td>
                            <td className={"text-nowrap"}>{exp.client?.phone}</td>
                            <td>{exp.notes}</td>
                            <td className={"text-center"}>{exp.weights?.leaving?.parcels}</td>
                            <td className={"text-center"}>{exp.weights?.leaving?.pallets}</td>
                            <td className={"text-center"}>{exp.weights?.arriving?.scrap}</td>
                            <td className={"text-center"}>{exp.weights?.arriving?.delivery}</td>
                            <td className={"text-center"}>{exp.palets?.leaving}</td>
                            <td className={"text-center"}>{exp.palets?.arriving}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Row>

            {/*<div className="pagebreak"></div>*/}

        </Container>
    );

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function handleClose() {
        onDismiss();
    }

    return (
        <ModalBackground zIndex={_zIndex}>
            <Modal show style={{zIndex: _zIndex + 1}} size={"xl"}>
                <Modal.Header>
                    <Modal.Title>Печат на документ</Modal.Title>
                    <CloseButton onClick={() => onDismiss()}/>
                </Modal.Header>
                <Modal.Body className={"p-5"}>
                    {printContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Затвори
                    </Button>
                    <Button variant="primary" onClick={handlePrint}>
                        <FontAwesomeIcon icon={faPrint} className={"me-2"}/>
                        Печат....
                    </Button>
                </Modal.Footer>
            </Modal>
        </ModalBackground>
    );
}
